import EventBus from '@/util/EventBus.js'
import { FontTypes } from '../../../../enums/FontTypes.js'
import MarginProperty from '../../common/margin-property/MarginProperty.vue'
import Editor from '@tinymce/tinymce-vue'
import getEnv from '@/util/env'
import { isObjEmpty } from '@/util/ValidateObject.js'
import { v4 as uuidv4 } from 'uuid'
import TextEditor from '@/components/TextEditor/TextEditor.vue'

export default {
  name: 'TextProperties',
  components: { MarginProperty, Editor, TextEditor },
  props: {
    landing: {},
    containerIndex: Number,
    slotIndex: Number,
    inComponent: Boolean,
  },
  data: function () {
    return {
      editorApiKey: getEnv('VUE_APP_TINY_API_KEY'),
      FontTypes: FontTypes,
      items: [],
      component: { type: Object, url: [], urlPreview: [] },
      init: {
        selector: 'textarea#full-featured-non-premium',
        plugins: 'fullscreen hr advlist lists wordcount quickbars emoticons link code',
        toolbar: ['undo redo forecolor backcolor numlist bullist indentlist alignment | link hr emoticons code', 'custonButton formButton attachmentButton AiButton'],
        toolbar_sticky: true,
        branding: false,
        image_advtab: false,
        importcss_append: true,
        height: 600,
        contextmenu: false,
        lineheight_formats: '1 1.2 1.5 2 2.3 2.5 3',
        quickbars_insert_toolbar: 'alignleft aligncenter alignright alignfull | outdent indent | link',
        quickbars_selection_toolbar: 'bold italic underline lineheight | fontselect fontsizeselect',
        noneditable_noneditable_class: 'mceNonEditable',
        toolbar_mode: 'floating',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px, color: #3F4254; }',
        target_list: [{ title: 'New page', value: '_blank', selected: true }],
        menubar: 'custom',
      },
      containerSlotCanvas: 1,
      addingMultimedia: false,
      tinymceKey: 1,
      test: true,
      eventUuid: '',
      renderEditor: false,
      textEditorUuid: '',
    }
  },
  watch: {
    'landing.customVars': {
      handler () {
        this.tinymceKey++
      },
      deep: true,
    },
  },
  created: function () {
    this.textEditorUuid = uuidv4()

    const that = this
    this.init.setup = function (editor) {
      if (!isObjEmpty(that.landing.customVars)) {
        editor.ui.registry.addSplitButton('custonButton', {
          text: that.$t('Campos personalizados'),
          onAction: function (_) {
          },
          onItemAction: function (buttonApi, value) {
            editor.insertContent(value)
          },
          fetch: function (callback) {
            const items = []
            for (const property in that.landing.customVars) {
              items.push({
              type: 'choiceitem',
              text: that.landing.customVars[property],
              value: '{' + property + '}',
              })
          }
            callback(items)
          },
        })
      }
      editor.ui.registry.addGroupToolbarButton('alignment', {
        icon: 'align-left',
        tooltip: 'Alignment',
        items: 'alignleft aligncenter alignright alignjustify',
      })
      editor.ui.registry.addGroupToolbarButton('indentlist', {
        icon: 'indent',
        tooltip: 'IndentList',
        items: 'outdent indent',
      })
      editor.ui.registry.addGroupToolbarButton('fontDecorations', {
        icon: 'bold',
        tooltip: 'FontDecorations',
        items: 'bold italic underline strikethrough | removeformat',
      })

      editor.ui.registry.addIcon('forms', '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 576 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M0 96C0 60.7 28.7 32 64 32H512c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM128 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm32-128a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM128 384a32 32 0 1 0 0-64 32 32 0 1 0 0 64zm96-248c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24H448c13.3 0 24-10.7 24-24s-10.7-24-24-24H224z"/></svg>')
      editor.ui.registry.addIcon('lightbulb', '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M272 384c9.6-31.9 29.5-59.1 49.2-86.2l0 0c5.2-7.1 10.4-14.2 15.4-21.4c19.8-28.5 31.4-63 31.4-100.3C368 78.8 289.2 0 192 0S16 78.8 16 176c0 37.3 11.6 71.9 31.4 100.3c5 7.2 10.2 14.3 15.4 21.4l0 0c19.8 27.1 39.7 54.4 49.2 86.2H272zM192 512c44.2 0 80-35.8 80-80V416H112v16c0 44.2 35.8 80 80 80zM112 176c0 8.8-7.2 16-16 16s-16-7.2-16-16c0-61.9 50.1-112 112-112c8.8 0 16 7.2 16 16s-7.2 16-16 16c-44.2 0-80 35.8-80 80z"/></svg>')
      editor.ui.registry.addIcon('paperclip', '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M364.2 83.8c-24.4-24.4-64-24.4-88.4 0l-184 184c-42.1 42.1-42.1 110.3 0 152.4s110.3 42.1 152.4 0l152-152c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-152 152c-64 64-167.6 64-231.6 0s-64-167.6 0-231.6l184-184c46.3-46.3 121.3-46.3 167.6 0s46.3 121.3 0 167.6l-176 176c-28.6 28.6-75 28.6-103.6 0s-28.6-75 0-103.6l144-144c10.9-10.9 28.7-10.9 39.6 0s10.9 28.7 0 39.6l-144 144c-6.7 6.7-6.7 17.7 0 24.4s17.7 6.7 24.4 0l176-176c24.4-24.4 24.4-64 0-88.4z"/></svg>')

      editor.ui.registry.addButton('formButton', {
        text: that.$t('Formularios'),
        icon: 'forms',
        onAction: function (_) {
          that.addingMultimedia = true
          that.showMultimedia('formularios')
        },
      })
      editor.ui.registry.addButton('attachmentButton', {
        text: that.$t('Adjuntos'),
        icon: 'paperclip',
        onAction: function (_) {
          that.addingMultimedia = true
          that.showMultimedia('adjuntos')
        },
      })
      editor.ui.registry.addButton('AiButton', {
        text: that.$t('Generar con IA'),
        icon: 'lightbulb',
        onAction: () => {
          that.openAiTextMailing()
        },
      })
    }

    this.component = null

    if (this.inComponent) {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0].textComponent
      console.log(this.component)
      this.containerSlotCanvas = this.landing.structure.containers[this.containerIndex].slots.length
    } else {
      this.component = this.landing.structure.containers[this.containerIndex].slots[this.slotIndex][0]
      this.containerSlotCanvas = this.landing.structure.containers[this.containerIndex].slots.length
    }

    this.initTinymce()
  },
  mounted: function () {
    const uuid = uuidv4()
    this.eventUuid = 'ai-mailing-text-' + uuid

    const that = this
    const elements = document.querySelectorAll('.tox .tox-edit-area__iframe')
    elements.forEach(function (elemen) {
      elemen.setAttribute('style', 'background-color: ' + that.landing.structure.containers[that.containerIndex].backgroundColor + ' !important;')
    })

    EventBus.$on('multimediaSelected', this.setComponentData)
    EventBus.$on('hook:beforeDestroy', () => {
      EventBus.$off('multimediaSelected', this.setComponentData)
    })
    EventBus.$on('AiTextAssistantMailingSendText', this.setAiText)
  },
  methods: {
    setAndShowHtmlDialog () {
      this.htmlContent = '' + this.quill.root.innerHTML
      this.showHtmlDialog = true
    },
    setHtmlEdition () {
      this.quill.root.innerHTML = this.htmlContent
      this.showHtmlDialog = false
    },
    formAction () {
      this.addingMultimedia = true
      this.showMultimedia('formularios')
    },
    attachmentAction () {
      this.addingMultimedia = true
      this.showMultimedia('adjuntos')
    },

    async setAiText (params) {
      if (params.eventUuid !== this.eventUuid) return
      await this.changeSizeComponet()

      const content = `<p style=" font-size: ${this.component.fontSizeContent}px;
      color: #3F4254;
      font-family: Helvetica,Arial,sans-serif;
    "> ${params.idea}</p>`

      this.component.tinymce += content
    },
    openAiTextMailing () {
      EventBus.$emit('AiTextAssistantMailingOpen', this.eventUuid)
    },
    setComponentData (url, type, fileId) {
      if (this.addingMultimedia) {
        // console.log('type file:', type) // No quitar log por ahora hasta que se aplique vuex
        if (type === 'attachment') {
          const link = getEnv('VUE_APP_ASSETS_URL') + url
          this.insertUrlTinymce(link, this.$t('Ir al adjunto'))
        }

        if (type === 'form') {
          const user = JSON.parse(localStorage.getItem('user'))
          this.component.urlPreview[fileId] = getEnv('backend_endpoint') + 'api/forms/preview/' + fileId + '?token=' + user.token
          this.component.url[fileId] = '[FORM_' + fileId + ']'
          this.insertUrlTinymce(this.component.urlPreview[fileId], this.$t('Ir al formulario'), fileId)
        }
        this.addingMultimedia = false
      }
    },
    addFieldToMessage (item, inputRef, componentVar) {
      if (item === null) {
        return
      }

      const fieldName = '{' + item + '}'
      this.insertFieldAtCursor(fieldName, inputRef, componentVar)
    },
    insertFieldAtCursor (myValue, inputRef, componentVar) {
      const tArea = this.$refs[inputRef]
      // get cursor's position:
      const startPos = tArea.selectionStart
      const tmpStr = tArea.value
      const endPos = tArea.selectionEnd
      let cursorPos = startPos

      // insert:
      this.component[componentVar] = tmpStr.substring(0, startPos) + myValue + tmpStr.substring(endPos, tmpStr.length)

      // move cursor:
      setTimeout(() => {
        cursorPos += myValue.length
        tArea.selectionStart = tArea.selectionEnd = cursorPos
      }, 10)
    },
    async changeSizeComponet () {
      if (this.containerSlotCanvas === 2) {
        this.component.fontSizeTitle = 18
        this.component.fontSizeContent = 12
      } else if (this.containerSlotCanvas === 4) {
        this.component.fontSizeTitle = 16
        this.component.fontSizeContent = 10
      }
    },
    async initTinymce () {
      await this.changeSizeComponet()

      if (this.component.tinymce === '') {
        this.component.tinymce = `<h2 style="
          text-align: center;
          font-size: ${this.component.fontSizeTitle}px;
          color: #3F4254;
          font-family:Helvetica,Arial,sans-serif;
        ">${this.component.title}</h2><p style="
          text-align: center;
          font-size: ${this.component.fontSizeContent}px;
          color: #3F4254;
          font-family: Helvetica,Arial,sans-serif;
        ">${this.component.content}</p>`
      }

      this.renderEditor = true
    },
    async insertUrlTinymce (url, text, formId = null) {
      await this.changeSizeComponet()

      const formIdDataAttr = formId ? `data-form-id="${formId}"` : ''

      const content = `<p style="
        text-align: center;
        font-size: ${this.component.fontSizeContent}px;
        color: #3F4254;
        font-family: Helvetica,Arial,sans-serif;
      ">
        <a
          style="
            text-align: center;
          "
          href="${url}"
          target="_blank"
          ${formIdDataAttr}
        >
          ${text}
        </a>
      </p>`

      this.component.tinymce += content
    },
    showMultimedia (currentRootDirectory) {
      EventBus.$emit('showMultimedia', currentRootDirectory, false)
    },
  },
}
